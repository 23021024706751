import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Typography, TextField, Button, Paper } from '@material-ui/core';
import Footer from '../components/Footer.js';

const styles = (theme) => ({
  mainContainer: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: '125px',
    paddingBottom: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgb(169, 197, 197)',
  },
  containerInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sectionTitle: {
    paddingTop: '5px',
    paddingBottom: '50px',
  },
  containerForm: {
    backgroundColor: '#ffffff',
    width: '90%',
    maxWidth: '500px',
    textAlign: 'center',
    padding: '15px',
  },
  formField: {
    marginTop: '10px',
    marginBottom: '15px',
  },
  formButton: {
    marginTop: '10px',
    marginBottom: '15px',
  },
  error: {
    color: '#FF0000',
  }
});

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      name: "",
      email: "",
      message: ""
    };
  }

  submitForm = event =>  {
    event.preventDefault();
    const {name, email, message } = this.state;

    if(this.validateForm(false)) {
      console.log('Form Validation Failed');
      return;
    } else {
      const data = {
        name: name,
        email: email,
        message: message,
      };

      console.log('data',data);

      const xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://formspree.io/xvowrgpj');
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          this.setState({
            name: "",
            email: "",
            message: "",
          });
          this.setState({ status: "SUCCESS" });
        } else {
          this.setState({ status: "ERROR" });
        }
      };
      xhr.send(JSON.stringify(data));
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });

    this.validateForm(true, event.target.id, event.target.value)

  }

  validateForm = (singleField, field, value) => {
    let error = false;

    if(field === "email" || !singleField) {
      const toValidate = singleField ? value : this.state.email;
      if(!this.validateEmail(toValidate)) {
        this.setState({emailError: 'Enter a Valid Email Address'});
        error = true;
      } else {
        this.setState({emailError: null});
      }
    }

    if(field === "name" || !singleField) {
      const toValidate = singleField ? value : this.state.name;
      if(!toValidate) {
        this.setState({nameError: 'Your Name is Required'});
        error = true;
      } else {
        this.setState({nameError: null});
      }
    }

    if(field === "message" || !singleField) {
      const toValidate = singleField ? value : this.state.message;
      if(!toValidate) {
        this.setState({messageError: 'A Message is Required'});
        error = true;
      } else {
        this.setState({messageError: null});
      }
    }

    if(!singleField) return(error);
  }

  validateEmail = (email) => {

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render(){
    const { classes } = this.props
    const { status, emailError, nameError, messageError } = this.state;

    return (
      <div className="contact">
      <Container disableGutters maxWidth={false} className={classes.mainContainer}>
        <Container className={classes.containerInner}>
          <Typography variant='h2' align='center' id='meet-us' className={classes.sectionTitle}>Contact Us</Typography>
            <Typography variant='h5' align='center'>
               We are located in Lake Geneva, Wisconsin.<br/>
               To contact us please fill out the form below and we will respond to you as quickly as possible.
            </Typography>
            <br/>
            <Typography variant='h5' align='center'>
               Want to reach us by phone? Call us at 262.475.0476
            </Typography>
            <br/>
            <Paper className={classes.containerForm}>
            <form
              onSubmit={this.submitForm}
              action="https://formspree.io/xvowrgpj"
              method="POST"
              >
              <TextField required id="name" name="name" label="Your Name" variant="outlined" onChange={this.handleChange} fullWidth className={classes.formField} error={nameError} helperText={nameError ? true : false} value={this.state.name}/>
              <br/>
              <TextField required id="email" name="email" label="Your Email"  variant="outlined" onChange={this.handleChange} fullWidth className={classes.formField} error={emailError} helperText={emailError ? true : false} value={this.state.email}/>
              <br/>
              <TextField required id="message" name="message" label="Message" variant="outlined" onChange={this.handleChange} multiline rows={5} fullWidth className={classes.formField} error={messageError ? true : false} helperText={messageError} value={this.state.message}/>
              <br/>

              {status === "SUCCESS" ? <p>Thank you for contacting us! We will be in touch shortly.</p> : <Button type="submit" variant="contained" className={classes.formButton}>Submit</Button>}
              {status === "ERROR" && <p className={classes.error}>Ooops! There was an error. Please try again.</p>}
              </form>

        </Paper>
      </Container>
    </Container>
    <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(Contact);
