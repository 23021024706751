import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import Footer from '../components/Footer.js';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';

const styles = (theme) => ({
  meetUsContainer: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: '125px',
    paddingBottom: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgb(169, 197, 197)',
  },
  ourLegacyContainer: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: '125px',
    paddingBottom: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgb(244, 244, 244)'
  },
  headshot: {
    width: '90%',
    imageRendering: '-webkit-optimize-contrast',
    maxWidth: '400px',
    padding: '20px',
    [theme.breakpoints.down("sm")]: {
      float: 'none'
    },
    [theme.breakpoints.up("md")]: {
      float: 'right'
    },
  },
  headshotWrapper: {
    textAlign: 'center',
  },
  sectionTitle: {
    paddingTop: '5px',
    paddingBottom: '50px',
  },
});

class About extends React.Component {
  render(){
    const { classes } = this.props;

    return (
      <div className="about">
      <Container disableGutters maxWidth={false} className={classes.meetUsContainer}>
        <Container>
          <Typography variant='h2' align='center' id='meet-us' className={classes.sectionTitle}>Meet Us</Typography>

              <Typography variant='h3'>Luke Pfeifer</Typography>
              <Typography variant='h5'>
                <div className={classes.headshotWrapper}><img src='/static/images/headshot-luke.png' alt='Luke Pfeifer' className={classes.headshot} /></div>
                <p>Luke is the founder and CEO of Global Logics, LLC.  Born and raised in the Sheboygan, Wisconsin area, Luke has a unique blend of over 25 years of technology and hospitality experience. Luke is a proud graduate of Lakeland College (now Lakeland University) having served in various student leadership positions including Student Body President and Lead Resident Assistant.</p>

                <p>Luke began his career in technology in 1995 developing websites and went on to own with his mom a regional internet service provider (ISP) in Wisconsin.  After they sold the internet provider, Luke decided to take on one of his childhood dreams of working in a hotel.  Luke started in a part-time role at a Holiday Inn Express while continuing to develop webpages and consultant with the new ownership group.  A few days after starting at the hotel a massive fire destroyed the office complex that was home to the ISP and the servers for Luke’s web development and hosting.  While Luke continued to develop websites, he took on more time working at the hotel. It was here that Luke developed his passion for hospitality, but he did not lose passion for technology as he began to work with the owner to develop his website and various internal systems in addition to his time at the hotel.</p>

                <p>In early 2002, Luke pushed the boundary of the hospitality industry by partnering with Pride Hospitality, LLC to become the first hotel in the State of Wisconsin to offer free wireless internet.  It was at this time Luke predicted that wireless internet in hotels would be a must much like TV or running water. Today, wireless internet is a common expectation in hotels throughout the world.  After growing the service, Luke sold it in 2004.</p>

                <p>While focusing on a few technology-related projects over the decade, Luke primarily focused on his hospitality career working for companies including Holiday Inn Express, Great Wolf Resorts, Lake Lawn Resort, and Kalahari Resorts.  He held positions including Executive Housekeeper, Assistant General Manager, Director of Guest Services, Front Office Manager, and Director of Rooms.  The properties he worked at ranged from a limited service hotel of 63 rooms all the way up to an 884 full-service resort with a waterpark and convention center.</p>

                <p>Luke loved hospitality operations but wanted to continue to grow with technology.  In 2009, Luke joined Agilysys a leading provider of global hospitality solutions.  At Agilysys he has served in a variety of roles with increasing responsibility. He led the design, development, and launch of the company's groundbreaking rGuest® Stay property management system. Luke left Agilysys in 2020 after serving as the Sr. Director of Product Management responsible for driving the vision and direction for Agilysys' hotel property management technology solutions including LMS®, Visual One®, and rGuest® Stay.  Luke joined Leasera, a startup focused on reinventing the leasing ecosystem, as Chief Product Officer in 2020.</p>

                <p>Luke has been married to his wife, Monica Pfeifer, for over 10 years and she has been a support throughout his career.  Monica is also a graduate of Lakeland College with a double major in Hospitality Management and Business Administration.  Monica has a wealth of hospitality experience including the PGA of America, Lake Lawn Resort, Kalahari Resorts, Benchmark Resorts & Hotels, and Columbia Hospitality.  She has served in roles from golf management to conference services management and is currently a Director of Events & Conference Services for a prestigious high-end boutique resort. Monica also has numerous years of technology experience in sales and as a product owner for a leading developer of SaaS solutions for homeowner associations and builders.  Monica loves photography and in 2009 expanded her photography by forming Monica Janea Photography as an offering of Global Logics. Luke and Monica have one daughter whom they love dearly.</p>
              </Typography>

      </Container>
      </Container>
      <Container disableGutters maxWidth={false} className={classes.ourLegacyContainer}>
        <Container className={classes.historyContainerInner}>
          <Typography variant='h2' align='center' id='our-legacy' className={classes.sectionTitle}>Our Legacy</Typography>
          <Container>
            <Timeline lineColor={'#ddd'}>
              <TimelineItem
                key="001"
                dateText="1995"
                style={{ color: '#013434' }}
                dateInnerStyle={{ background: '#013434', color: '#ffffff' }}
              >
                <Typography variant='h5'>
                  Global Logics was formed in January 1995 as Effective Internet Pages in Sheboygan, Wisconsin by Luke Pfeifer.  Effective Internet Pages, the premier web site design company in Sheboygan, provided html web design and graphic design.
                </Typography>
              </TimelineItem>
              <TimelineItem
                key="002"
                dateText="1997"
                style={{ color: '#013434' }}
                dateInnerStyle={{ background: '#013434', color: '#ffffff' }}
              >
                <Typography variant='h5'>
                  In 1997 the company was incorporated in Wisconsin and its name was officially changed to Staff Networks Incorporated (dba Staff.Net).  During 1997 Staff.Net added a variety of new offerings which included CGI programming, computer retail sales, and network consulting and installation.
                </Typography>
              </TimelineItem>
              <TimelineItem
                key="003"
                dateText="1997-1999"
                style={{ color: '#013434' }}
                dateInnerStyle={{ background: '#013434', color: '#ffffff' }}
              >
                <Typography variant='h5'>
                  In November of 1997, Luke, founder of Global Logics, opened an internet service provider in Sheboygan, with his mom, under the name of Digital Axis Incorporated.  Digital Axis provided dial-up, ISDN, and dedicated internet access to southeastern Wisconsin, including Milwaukee,  Sheboygan, Fond Du Lac, and the Fox Cities.  In 1999, Digital Axis Incorporated was sold to DotNet Internet Services of Fond Du Lac.                  </Typography>
              </TimelineItem>
              <TimelineItem
                key="004"
                dateText="1999"
                style={{ color: '#013434' }}
                dateInnerStyle={{ background: '#013434', color: '#ffffff' }}
              >
                <Typography variant='h5'>
                  Following the sale, Luke began his career in hospitality while continuing to focus efforts on Staff.Net.  Staff.Net hit a landmark when it formed Easy Domains in August of 1999 to provide domain name registration services.  Global Logics continues to operate under the Easy Domains name today at http://www.easydomains.com.
                </Typography>
              </TimelineItem>
              <TimelineItem
                key="005"
                dateText="2000"
                style={{ color: '#013434' }}
                dateInnerStyle={{ background: '#013434', color: '#ffffff' }}
              >
                <Typography variant='h5'>
                  On May 1, 2000 Staff Networks Incorporated officially changed names to Global Logics, Inc.
                </Typography>
              </TimelineItem>
              <TimelineItem
                key="006"
                dateText="2002-2004"
                style={{ color: '#013434' }}
                dateInnerStyle={{ background: '#013434', color: '#ffffff' }}
              >
                <Typography variant='h5'>
                During 2002 Global Logics began offering the installation of high-speed wireless internet access to hotel operators throughout the United States under the name Global Logics' Guest Link.  Global Logics began the revolution of free WIFI internet hotel, when it installed what would be the first hotel to offer such a service in the State of Wisconsin.  After installing and servicing numerous properties this division of Global Logics was sold to Sboca Wireless Networks in February of 2004.
                </Typography>
              </TimelineItem>
              <TimelineItem
                key="007"
                dateText="2009"
                style={{ color: '#013434' }}
                dateInnerStyle={{ background: '#013434', color: '#ffffff' }}
              >
                <Typography variant='h5'>
                In 2009, the business was expanded to include photography (doing business as Monica Janea Photography), which is led by Luke’s wife, Monica.  Photography services include family photographs, senior photographs, weddings, scenery, and more.
                </Typography>
              </TimelineItem>
              <TimelineItem
                key="008"
                dateText="2020"
                style={{ color: '#013434' }}
                dateInnerStyle={{ background: '#013434', color: '#ffffff' }}
              >
                <Typography variant='h5'>
                In 2020, Global Logics officially launched Wishlist.guru, an app for tracking and sharing gift wishes.  The app was originally developed by Luke for use by his family in 2017.  Seeing the need for better tracking of gifts and to avoid duplicates Luke decided to further develop the app and make it available for the public to use.
                </Typography>
              </TimelineItem>
              <TimelineItem
                key="009"
                dateText="Present"
                style={{ color: '#013434' }}
                dateInnerStyle={{ background: '#013434', color: '#ffffff' }}
              >
                <Typography variant='h5'>
                <p>Today Global Logics provides services to a wide array of industries but is mainly focused on the hospitality industry and photography services for commercial and personal clients. Services provided include but are not limited to photography, domain name registration, web hosting, web page design, database programming services, and custom back end programming.  Global Logics also develops and customizes numerous hospitality back end web-based solutions. </p>

                <p>The company's passion for the hospitality industry combined with the knowledge and passion for technology allows Global Logics to offer a unique combination of technology services to the hospitality industry.  Global Logics truly has a passion for the work it provides to all of its clients.</p>
                </Typography>
              </TimelineItem>
            </Timeline>
        </Container>
      </Container>
      </Container>
      <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(About);
