import withRoot from './withRoot';

import React from 'react';
import NavBar from './components/NavBar.js';
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import About from "./containers/About";
import Contact from "./containers/Contact";

class Site extends React.Component {
  render(){
    return (
      <div className="App">
        <NavBar />
        <Switch>
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/" exact component={Home} />
          <Route component={NotFound}  />
        </Switch>
      </div>
    );
  }
}

export default withRoot(Site);
