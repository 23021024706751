import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, Button } from '@material-ui/core';
import { HashLink } from 'react-router-hash-link';

const styles = (theme) => ({
 menuItem: {
   fontWeight: '600'
 }
});


class NavItem extends React.Component {
  render(){
    const { classes, children, path, renderAs, closeMenu } = this.props;
    const ref = React.createRef();

    const LinkRef = React.forwardRef((props, ref) => (
      <HashLink {...props} />
    ));

    return (
      <span>
      { renderAs === 'Menu' ?
          <MenuItem color="inherit" component={LinkRef} ref={ref} to={process.env.PUBLIC_URL+path} onClick={closeMenu} className={classes.menuItem}>{children}</MenuItem>
        :
          <Button color="inherit" component={LinkRef} ref={ref} to={process.env.PUBLIC_URL+path}>{children}</Button>
      }
      </span>
    );
  }
}

export default withStyles(styles)(NavItem);
