import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = (theme) => ({
  shell: {
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    right: '0px',
    left: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop : '100px',
    background: '#676767',
  },
  title: {
    color: '#ffffff'
  },
});

class NotFound extends React.Component {
  render(){
    const { classes } = this.props;

    return (
      <div className={classes.shell}>
        <Typography className={classes.title} variant='h2'>
          Oops.  That Page Doesn't Seem to Exist!
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(NotFound);
