import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

const styles = (theme) => ({
  containerFooter: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: '25px',
    paddingBottom: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgb(255, 255, 255)',
  },
});

class Footer extends React.Component {
  render(){
    const { classes } = this.props;

    return (
      <Container disableGutters maxWidth={false} className={classes.containerFooter}>
        <Typography>Copyright 2020 Global Logics, LLC</Typography>
      </Container>
    );
  }
}

export default withStyles(styles)(Footer);
