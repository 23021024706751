import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { HashLink } from 'react-router-hash-link';
import { AppBar, Toolbar, IconButton, Menu, useScrollTrigger } from '@material-ui/core';
import NavItem from './NavItem.js';
import MenuIcon from "@material-ui/icons/Menu";

const styles = (theme) => ({
  navBar: {
    flexGrow: 1,
    background: 'rgba(0, 70, 70, 0.65)',
  },
  navBarScrolled: {
    flexGrow: 1,
    background: 'rgba(0, 70, 70, 1)',
  },
  title: {
    flexGrow: 1,
    textAlign: 'left'
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      height: '40px',
      paddingTop: '5px'
    },
    [theme.breakpoints.up("md")]: {
      height: '45px',
      paddingTop: '5px'
    },
  },
  menuExpanded: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  menuCollapsed: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  menuOverlay: {
    background: 'rgba(50,50,50,0.5)',
  },
});

function AppBarScroll(props) {
  const { children, classes } = props;
  const trigger = useScrollTrigger({disableHysteresis: true});
  console.log('trigger', trigger);
  if(trigger) {
    return (
      <AppBar className={classes.navBarScrolled}>
        {children}
      </AppBar>
    )
  } else {
    return (
      <AppBar className={classes.navBar}>
        {children}
      </AppBar>
    )
  }
}

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
    this.handleMenu = this.handleMenu.bind(this);
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuSelect = (event, history) => {
    event.preventDefault();
    history.push(event.currentTarget.getAttribute("path"));
    this.setState({ anchorEl: null });
  };

  preventDefault = (event, history) => {
    event.preventDefault();
  };


  render(){
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <AppBarScroll {...this.props}>
        <Toolbar>
          <div className={classes.title}>
            <HashLink to='/#home'><img src="/static/images/globallogics-logo-nav.png"  className={classes.logo} alt="Global Logics" /></HashLink>
          </div>

          <span className={classes.menuExpanded}>
            <NavItem path='/#consulting'>Hospitality Consulting</NavItem>
            <NavItem path='/#othersolutions'>Other Solutions</NavItem>
            <NavItem path='/about'>About</NavItem>
            <NavItem path='/contact'>Contact</NavItem>
          </span>
          <span className={classes.menuCollapsed}>
            <IconButton onClick={this.handleMenu}>
              <MenuIcon style={{ color: '#FFFFFF' }}/>
            </IconButton>
            <Menu
              elevation={0}
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              open={open}
              onClose={this.handleClose}
              className={classes.menuOverlay}
              PaperProps={{
                style: {
                  background: 'rgb(0,40,40)',
                  color: 'rgb(255,255,255)'
                }
              }}
              >
                <NavItem path='/#consulting' renderAs='Menu' closeMenu={this.handleClose}>Hospitality Consulting</NavItem>
                <NavItem path='/#othersolutions' renderAs='Menu' closeMenu={this.handleClose}>Other Solutions</NavItem>
                <NavItem path='/about' renderAs='Menu' closeMenu={this.handleClose}>About</NavItem>
                <NavItem path='/contact' renderAs='Menu' closeMenu={this.handleClose}>Contact</NavItem>
              </Menu>
          </span>
        </Toolbar>
      </AppBarScroll>
    );
  }
}

export default withStyles(styles)(NavBar);
