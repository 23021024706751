import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import HeroLayout from '../components/HeroLayout.js';
import { Container, Typography, Grid, Link } from '@material-ui/core';
import Footer from '../components/Footer.js';

const backgroundImage =
  '/static/images/home-hero-globehand.jpeg';

const styles = (theme) => ({
  root: {
    color: theme.palette.common.white,
  },
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    [theme.breakpoints.down("xs")]: {
      backgroundPosition: '-175px',
    },
    [theme.breakpoints.up("md")]: {
      backgroundPosition: '0px 30px',
    },
  },
  containerConsulting: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: '25px',
    paddingBottom: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgb(169, 197, 197)',
  },
  containerOtherSolutions: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: '25px',
    paddingBottom: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgb(244, 244, 244)'
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  heroTitle: {
    color: '#ffffff',
  },
  detailTitle: {
    marginTop: '10px',
    marginBottom: '0px',
  },
  detailImage: {
    width: '90%',
    maxWidth: '400px',
    verticalAlign: 'middle',
    imageRendering: '-webkit-optimize-contrast',
  },
  sectionTitle: {
    paddingTop: '65px',
    paddingBottom: '50px',
  },
  detailImageWrapper: {
    lineHeight: '150px',
    height: '150px',
    textAlign: 'center'
  },
  detailImageWrapperIcon: {
    lineHeight: '150px',
    height: '150px',
    textAlign: 'center'
  },
  detailLink: {
    marginTop: '0px',
    marginBottom: '15px',
  },
  detailText: {
    width: '100%',
  },
  sectionBackground: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
  containerConsultingInner: {
    backgroundImage: `url('/static/images/background-trans-2w.png')`
  },
  containerOtherSolutionsInner: {
    backgroundImage: `url('/static/images/background-trans-1.png')`
  }
});

class Home extends React.Component {
  render(){
    const { classes } = this.props;

    return (
      <div className="home" id="home">
        <HeroLayout backgroundClassName={classes.background}>
          <Typography  variant='h1' align='left' className={classes.heroTitle}>Your Logical Solutions Partner</Typography>
        </HeroLayout>
        <Container disableGutters maxWidth={false} className={classes.containerConsulting}>
          <Container className={classes.containerConsultingInner}>
          <Typography variant='h2' align='center' className={classes.sectionTitle} id="consulting">Hospitality Consulting</Typography>
            <Grid container spacing={4}>
              <Grid item sm={12} md={4}>
                <div className={classes.detail}>
                  <div className={classes.detailImageWrapperIcon}>
                    <img src="/static/images/icon-hotel.png" className={classes.detailImage} alt='PMS Consulting and Selection' />
                  </div>
                 <Typography  variant='h6' className={classes.detailTitle}>PMS Consulting and Selection</Typography>
                 <Typography variant='h5'>
                  Needing help to select, implement, or project managing an existing or new PMS solution? <br/><br/> Global Logics has an extensive background in hospitality property management systems. Our team's background expands from first-hand experience using PMS solutions suc has front office and rooms management roles to roles as senior leaders building some of the most well-known on-premise and cloud PMS solutions.                 </Typography>
                </div>
              </Grid>
              <Grid item sm={12} md={4}>
                <div className={classes.detail}>
                  <div className={classes.detailImageWrapperIcon}>
                    <img src="/static/images/icon-code.png" className={classes.detailImage} alt='Custom Development' />
                  </div>
                  <Typography  variant='h6' className={classes.detailTitle}>Custom Development</Typography>
                  <Typography variant='h5'>
                  We have a variety of experience in web programming and database languages including NodeJS, PHP, HTML, JavaScript, PostgreSQL, MySQL, and MongoDB.  Our solutions have leveraged some of the largest cloud providers including AWS, Rackspace, and Azure.  We build solutions that are custom to your needs without a bunch of bloat.  Our focus is on delivering solutions that work and are not over-engineered.
                   <br/><br/>Our team has experience in leadership roles at a global hospitality solution company focusing on PMS and POS solutions.                  </Typography>
                </div>
              </Grid>
              <Grid item sm={12} md={4}>
                <div className={classes.detail}>
                  <div className={classes.detailImageWrapperIcon}>
                    <img src="/static/images/icon-sitemap.png" className={classes.detailImage} alt='Web Design & Development' />
                  </div>
                  <Typography  variant='h6' className={classes.detailTitle}>Web Design & Development</Typography>
                  <Typography variant='h5'>
                  Global Logics has been doing web design and development since 1995.  Hospitality is in our blood and we treat every client just like you would treat your most loyal guests.
                  <br/><br/>Our clients over the years are not only from the hospitality industry but many other major industries.  Highlighted clients we have worked with include Pride Hospitality, Little Angel Child Care, Elegant Elements, and more.  Our experience ranges from consumer-facing websites to internal intranet portals.</Typography>
                </div>
              </Grid>
            </Grid>
          </Container>





        </Container>
        <Container disableGutters maxWidth={false} className={classes.containerOtherSolutions}>
          <Container className={classes.containerOtherSolutionsInner}>
          <Typography variant='h2' align='center' className={classes.sectionTitle} id="othersolutions">Other Solutions</Typography>
            <Grid container spacing={4}>
              <Grid item sm={12} md={4}>
                <div className={classes.detail}>
                  <div className={classes.detailImageWrapper}>
                    <Link href="https://www.monicajanea.com"><img src="/static/images/mjp-logo.png" className={classes.detailImage} alt='Monica Janea Photography' /></Link>
                  </div>
                  <Typography  variant='h6' className={classes.detailTitle}>Photography</Typography>
                  <Typography variant='h5' className={classes.detailLink}>
                    <Link href="https://www.monicajanea.com">monicajanea.com</Link>
                  </Typography>
                  <Typography variant='h5' align='left' className={classes.detailText}>
                    Monica Janea Photography has been providing photography services for families, weddings, and events since 2009.
                  </Typography>
                </div>
              </Grid>
              <Grid item sm={12} md={4}>
                <div className={classes.detail}>
                  <div className={classes.detailImageWrapper}>
                    <Link href="http://www.easydomains.com"><img src="/static/images/easydomains-logo.png" className={classes.detailImage} alt='Easy Domains' /></Link>
                  </div>
                  <Typography  variant='h6' className={classes.detailTitle}>Domain Names</Typography>
                  <Typography variant='h5' className={classes.detailLink}>
                    <Link href="http://www.easydomains.com">easydomains.com</Link>
                  </Typography>
                  <Typography variant='h5' align='left' className={classes.detailText}>
                    Since 1999 Easy Domains has been providing domain name registration services.
                  </Typography>
                </div>
              </Grid>
              <Grid item sm={12} md={4}>
                <div className={classes.detail}>
                <div className={classes.detailImageWrapper}>
                  <Link href="https://app.wishlist.guru"><img src="/static/images/wlg-logo.png" className={classes.detailImage} alt='Wishlist Guru' /></Link>
                </div>
                  <Typography  variant='h6' className={classes.detailTitle}>Wishlist Guru</Typography>
                  <Typography variant='h5' className={classes.detailLink}>
                    <Link href="https://app.wishlist.guru" >app.wishlist.guru</Link>
                  </Typography>
                  <Typography variant='h5' align='left' className={classes.detailText}>
                    Our newest offering, Wishlist.Guru, is the easiest way to keep track of your wish list for events like birthdays and holidays.  Friends and family can easily track what you or your children would like to receive while doing our best to prevent those inevitable duplicate gifts.  This app is currently in beta and we look forward to your feedback!
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(Home);
